import { forwardRef, useCallback } from 'react';
import styled                      from 'styled-components';

export const SelectInput = forwardRef((props, ref) => {
  const { defaultValue, options, theme, field, form: { errors, setFieldValue }} = props;

  const onChange = useCallback(
    ({ target: { value } }) => {
      setFieldValue(field.name, value);
    },
    [field.name, setFieldValue]
  );

  const optionArray = options.map((option) => {
    return (
      <option key={`${option.label}${option.value}`} disabled={option.disabled} value={option.value}>
        {option.label}
      </option>
    );
  });

  return (
    <SelectInput.Container theme={theme}>
      <SelectInput.Select {...props} name={field.name} value={field.value} ref={ref} defaultValue={defaultValue} onChange={onChange}>
        {optionArray}
      </SelectInput.Select>
      {errors[field.name] && <SelectInput.Error>{errors[field.name] ?? ''}</SelectInput.Error>}
    </SelectInput.Container>
  );
});

SelectInput.Select = styled.select(({ theme }) => {
  const { breakpoint, color, size } = theme;
  
  return `
    -webkit-appearance    : none;
    -webkit-border-radius : 0;
    appearance            : none;
    box-sizing            : border-box;
    height                : ${size.formElementHeight};
    padding               : ${size.formElementPadding};
    padding-top           : 0;
    padding-bottom        : 0;
    font-size             : 0.75rem;
    line-height           : 1.3;
    background            : ${color.grayscale[100]};
    border                : 1px solid ${color.grayscale[300]};
    width                 : 100%;
    cursor                : pointer;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      border-color: ${color.grayscale[900]};
    }

    &:invalid {
      border-color: $messages-error;
    }

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 0.875rem;
    }
  `;
});

SelectInput.Container = styled.div(({ theme }) => {
  const { color } = theme;

  return `
    position      : relative;
    margin-bottom : 10px;

    &::after {
      content        : '';
      position       : absolute;
      top            : 50%;
      right          : 12px;
      width          : 6px;
      height         : 6px;
      margin-top     : -4px;
      border-style   : solid;
      border-width   : 0 0 1px 1px;
      border-color   : ${color.grayscale[700]};
      transform      : rotate(-45deg);
      cursor         : pointer;
      pointer-events : none;
    }
  `;
});

SelectInput.Error = styled.p`
  margin-top : 6px;
  color      : red;
  font-size  : 12px;
  position   : absolute;
`;

SelectInput.displayName = 'Select input';
