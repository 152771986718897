import { useCallback }      from 'react';
import styled               from 'styled-components';
import { Button }           from '../../../../components/Button';
import { numberWithCommas } from '../../../../shared/commonFunctions';

const environment = process.env.REACT_APP_ENVIRONMENT;

export const Approved = ({ firstName, lastName, creditLimit }) => {
  const onReturnButton = useCallback(
    () => {
      window.location.replace(environment === 'production' ? 'https://www.arhaus.com' : 'https://arhausllc.myshopify.com');
    },
    []
  );

  return (
    <>
      <p><strong>{firstName} {lastName}, your Arhaus Archarge credit card has been approved with a ${numberWithCommas(creditLimit)} credit limit!</strong></p>

      <p>
        Your new Archarge credit card will arrive in the mail within next 10 business days, but you can start using it now!
        Simply select "Use my Instant Archarge" at checkout.
      </p>

      <p>
        If you'd like to add more items to your shopping bag, click "Return to Shopping" bellow.
      </p>

      <p>
        Questions? Need assistance? Call 1.866.427.4287 or view our <a href="/">Archarge account</a>.
      </p>
      <Approved.ButtonContainer>
        <Button onClick={onReturnButton}>Return to Shopping</Button>
      </Approved.ButtonContainer>
    </>
  )
}

Approved.ButtonContainer = styled.div`
  && {
    display         : flex;
    margin-top      : 30px;
    flex-flow       : row nowrap;
    justify-content : center;
  }
`;