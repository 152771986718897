import { ThemeProvider } from 'styled-components';
import Geocode           from 'react-geocode';
import GlobalStyles      from './components/GlobalStyles';
import { Archarge }      from './modules/Archarge';
import arhausTheme       from './theme';

const GEOCODE_API_KEY = 'AIzaSyB9WwLScaegQDGFRMWOjzMv0zO475O73hc';
Geocode.setApiKey(GEOCODE_API_KEY);

export const App = () => (
  <ThemeProvider theme={arhausTheme}>
    <GlobalStyles />
    <Archarge />
  </ThemeProvider>
);
