import styled     from 'styled-components';
import { Grid }   from '../../Grids';
import { Layout } from '../components/Layout';

export const AuthorizedBuyers = () => (
  <Layout title="">
    <Grid>
      <AuthorizedBuyers.Title>IMPORTANT INFORMATION ABOUT OPENING AN ACCOUNT:</AuthorizedBuyers.Title>

      <p>
        To help the government fight the funding of terrorism and money laundering activities, 
        Federal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account. 
        What this means for you: When you open an account, we will ask for your name, address, date of birth or other information that will allow us to identify you. 
        We may also ask to see your driver’s license or other identifying documents.
      </p>

      <p>
        <strong>CA Residents:</strong> If you are married, you may apply for a separate account. <strong>OH Residents:</strong> The Ohio laws against discrimination require that all creditors make credit equally available to all creditworthy customers, 
        and that credit reporting agencies maintain separate credit histories on each individual upon request. 
        The Ohio Civil Rights Commission administers compliance with this law. <strong>NY, RI, and VT Residents:</strong> We may order credit reports in connection with processing applications/solicitations and any update, renewal or extension of credit. 
        Upon request, we will tell you the name and address of any consumer-reporting agency that furnished a report on you. 
        You consent to the obtaining of such reports by signing or otherwise submitting an application or solicitation.
      </p>

      <p>
        <strong>
          <u>Important Information for Covered Borrowers Under the Military Lending Act</u>
        </strong>
      </p>

      <p>
        Federal law provides important protections to members of the Armed Forces and their dependents relating to extensions of consumer credit. 
        In general, the cost of consumer credit to a member of the Armed Forces and his or her dependent may not exceed an annual percentage rate of 36 percent. 
        This rate must include, as applicable to the credit transaction or account: The costs associated with credit insurance premiums; fees for ancillary products 
        sold in connection with the credit transaction; any application fee charged (other than certain application fees for specified credit transactions or accounts); 
        and any participation fee charged (other than certain participation fees for a credit card account). 
        To hear this Military Lending Act disclosure and the payment obligations thereunder, call toll free at 1-866-230-0418; (TDD/TTY: 1-800-695-1788).
      </p>
    </Grid>
  </Layout>
);

AuthorizedBuyers.Line = styled.div`
  width            : 100%;
  height           : 2px;
  background-color : #24362F;
`;

AuthorizedBuyers.Title = styled.h3`
  font-size   : 14px;
  font-weight : bold;
  margin-top  : 25px;
`;