import styled          from 'styled-components';
import { footerLinks } from '../../shared/footerLinks';

const linkArray = footerLinks.map((link) => {
  return (
    <li key={link.linkText.replace(/ /g, '')}>
      {link.newWindow ? (
        <a href={link.url} target="_blank" rel="noreferrer">
          {link.linkText}
        </a>
      ) : (
        <a href={link.url}>{link.linkText}</a>
      )}
    </li>
  );
});

export const Footer = () => (
  <Footer.Wrapper>
    <nav>
      <Footer.NavList>{linkArray}</Footer.NavList>
    </nav>
  </Footer.Wrapper>
);

Footer.Wrapper = styled.footer(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    border-top : 1px solid ${color.grayscale[300]};
    padding    : 15px 0 16px;
    max-width  : 727px;
    min-width  : 320px;
    margin-top : 37px;

    @media screen and (min-width: ${breakpoint.phone}) {
      padding: 15px 0 52px;
    }
  `;
});

Footer.NavList = styled.ul(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    list-style      : none;
    margin          : 0;
    padding         : 0;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    align-items     : center;

    @media screen and (min-width: ${breakpoint.phone}) {
      justify-content: center;
    }

    li {
      margin      : 0;
      padding     : 0;
      text-align  : center;
      line-height : 1;

      @media screen and (min-width: ${breakpoint.phone}) {
        padding: 0 11px;
      }
    }

    a {
      color           : ${color.grayscale[950]};
      font-size       : 0.625rem;
      text-decoration : none;

      @media screen and (min-width: ${breakpoint.phone}) {
        font-size: 0.6875rem;
      }
    }
  `;
});


