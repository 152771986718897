import styled from 'styled-components';

export const Layout = ({ children }) => (
  <Layout.Main>
    <Layout.Section>
      <h2>Archarge <span>credit card</span></h2>
    </Layout.Section>

    {children}
  </Layout.Main>
);

Layout.Main = styled.main`
  font-size      : 16px;
  font-weight    : 400;
  line-height    : 1.4;
  letter-spacing : .7px;
  color          : #60605c;
  text-align     : center;
  box-sizing     : border-box;
  display        : block;
  margin         : 0 auto;
  padding        : 0 7vw;
  max-width      : 900px;
  min-width      : 370px;
  margin         : 0 auto;
  font-size      : 0.875rem;
  text-align     : justify;
`;

Layout.Section = styled.section`
  text-align : left;
  box-sizing : border-box;
  display    : block;
  padding    : 30px;
  text-align : center;

  h2 {
    text-align: center;
  }
`;