import { useCallback, useState, useRef, useEffect } from 'react';
import Geocode                           from 'react-geocode';
import { Layout }                        from '../../components/Layout';
import { Header }                        from '../../components/Header';
import { Footer }                        from '../../components/Footer';
import { MainPage }                      from './pages/MainPage';
import { ResponsePage }                  from './pages/ResponsePage';
import { callApi }                       from '../../shared/apiCaller';
import { STATUSES }                      from '../../shared/statuses';
import { useCookies }                    from 'react-cookie';
import { MaitenancePage } from './pages/MaitenancePage';


export const Archarge = () => {
  const [responseData, setResponseData]       = useState(null);
  const [validateOnChange, setValidateStatus] = useState(false);
  const [loading, setLoadingStatus]           = useState(false);
  const formikRef                             = useRef(null);
  const [, setCookie]                         = useCookies(['ArchargeInstantApprove']);
  const [ip, setIP]                           = useState('');
  const isDownForMaintenance                  = false;

  const onSubmit = useCallback(
    async (values) => {
      setLoadingStatus(true);
      
   

      const requestData = {
        email          : values.email,
        posType        : 'E',
        purchaseAmount : 2500,
        recordType     : 'I',
        storeNumber    : 9991,
        indicators     : {
          customerFacingInd: 'Y',
        },
        primaryApplicant: {
          name: {
            firstName     : values.firstName.toUpperCase(),
            middleInitial : values.middleInitial || '',
            lastName      : values.lastName.toUpperCase(),
          },
          address: {
            address1 : values.address1,
            address2 : values.address2 || '',
            city     : values.city,
            state    : values.state,
            zipCode  : +values.zip,
            country  : 'US'
          },
          demographics: {
            ssn          : +values.ssn.split('-').join(''),
            birthDate    : +`${values.yearOfBirth}${values.monthOfBirth}${values.dayOfBirth}`,
            incomeAmount : +values.annualIncome.replace('$', '').replace(',', ''),
            incomeInd    : 'A'
          }, 
          phoneNumber: {
            home: +values.phone.split('-').join(''),
            cell: +values.workPhone.split('-').join(''),
          }
        },
        iovation: {
          blackBox: document.getElementById('blackBox').value ? document.getElementById('blackBox').value : window['ioGetBlackbox']().blackbox,
          ipAddress:  ip
        }
      }
      
      
      await callApi('Archarge/ApplyInstantCredit', 'POST', requestData).then(response => {
        setResponseData(response.data);
        if(response.data)
        {
          var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
          setCookie("ArchargeInstantApprove",response.data.encryptedDetails,{domain:'.arhaus.com',path: '/', expires: currentDate});
        }

        setLoadingStatus(false);
      }).catch((error) => {
        if (error.response.status === 400) {
          setResponseData({ returnCode: STATUSES.TECHNICAL_ERROR })
        }

        setLoadingStatus(false);
      });

    }, [ip, setCookie]
  );

  //creating function to load ip address from the API
  const getIpAddress = async () => {
    const res =  await callApi('https://geolocation-db.com/json/', 'GET')
    setIP(res.data.IPv4)
  };


  useEffect(() => {
    //passing getData method to the lifecycle method
    getIpAddress()
  }, [])

  const getAddressByZipcode = useCallback(
    async ({ target: { value }}) => {
      Geocode.fromAddress(`${value}, US`).then(response => {
        const results          = response.results[0];
        const address          = results.formatted_address.split(', ');
        const hasAddress       = address.length > 3;
        const formattedAddress = address.slice(hasAddress ? 1 : 0).join(', ').split(', ');
        const city             = formattedAddress[0];
        const state            = formattedAddress[1].split(' ')[0];

        formikRef?.current?.setValues({ ...formikRef.current.values, city, state });
      });
    },
    []
  );


  return (
  <>
    <Header />
  {isDownForMaintenance ? (<Layout><MaitenancePage/><Footer /> </Layout> ):
  (
      <Layout returnCode={responseData?.returnCode ?? null}>
        {!responseData && (
          <MainPage 
            onSubmit            = {onSubmit} 
            getAddressByZipcode = {getAddressByZipcode} 
            formikRef           = {formikRef}
            validateOnChange    = {validateOnChange}
            setValidateStatus   = {setValidateStatus}
            loading             = {loading}
          />
        )}
        {responseData && (
          <ResponsePage 
            returnCode  = {responseData.returnCode}
            firstName   = {responseData.firstName}
            lastName    = {responseData.lastName}
            creditLimit = {responseData.creditLimit}
          />
        )}
        
        <Footer />
      </Layout>
    
  )}
  </>
)}
