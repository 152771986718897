import styled from 'styled-components';

export const Label = ({ required, children }) => (
  <Label.Wrapper>{children}{required ? <span>*</span> : null}</Label.Wrapper>
);

Label.Wrapper = styled.label(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    color          : ${color.grayscale[900]};
    font-size      : 0.75rem;
    display        : inline-block;
    vertical-align : top;
    margin         : 0 0 9px;

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 0.875rem;
      margin   : 0 0 5px;
    }

    span {
      color: #991e1e;
    }
  `;
});