import { Field }                                 from 'formik';
import styled                                    from 'styled-components';
import { Layout }                                from '../components/Layout';
import { TextInput }                             from '../../TextInput';
import { Grid, HalfGrid, SplitGrid, CustomGrid } from '../../Grids';
import { SelectInput }                           from '../../SelectInput';
import { Label }                                 from '../../Label';
import { NumberInput }                           from '../../NumberInput';

const currentYear = new Date().getFullYear();

const MONTHS = [{
  label: '',
  value: null,
}, ...Array.from({length: 12}, (item, i) => ({
  label : new Date(0, i).toLocaleString('en-US', {month: 'long'}),
  value :i >= 9 ? ++i : `0${++i}`
}))];

const DAYS = [{
  label : '',
  value : null,
}, ...Array.from({ length: 31}, (item, i) => ({
  label : ++i,
  value : i >= 10 ? i : `0${i}`
}))];

const getYearsOptions = (start, stop, step) => [
  ...Array.from({ length: (stop - start) / step + 1}, (_, i) => ({ 
    label: start + (i * step), 
    value: start + (i * step) 
  })), {
    label: '',
    value: null,
  }].reverse();

export const PersonalInformation = () => (
  <Layout title="Personal Information">
    <Grid>
      <SplitGrid>
        <HalfGrid>
          <Label required>First Name</Label>
          <Field id="firstName" name="firstName" component={TextInput} />
        </HalfGrid>

        <HalfGrid>
          <Label required>Last Name</Label>
          <Field id="lastName" name="lastName" component={TextInput} />
        </HalfGrid>
      </SplitGrid>
    </Grid>

    <Grid>
        <HalfGrid>
          <Label>Middle Initial</Label>
          <Field id="middleInitial" name="middleInitial" component={TextInput} />
        </HalfGrid>
    </Grid>


    <Grid>
      <HalfGrid>
        <Label required>Social Security Number</Label>
        <Field id="ssn" name="ssn" component={NumberInput} format="###-##-####"  />
      </HalfGrid>
    </Grid>

    <Grid>
      <PersonalInformation.Title>Date Of Birth</PersonalInformation.Title>
      <SplitGrid>
        <CustomGrid width="32%">
          <Label required>Month</Label>
          <Field 
            id        = "monthOfBirth"
            name      = "monthOfBirth"
            component = {SelectInput}
            options   = {MONTHS}
          />
        </CustomGrid>

        <CustomGrid width="32%">
          <Label required>Day</Label>
          <Field 
            id        = "dayOfBirth"
            name      = "dayOfBirth"
            component = {SelectInput}
            options   = {DAYS}
          />
        </CustomGrid>

        <CustomGrid width="32%">
          <Label required>Year</Label>
          <Field 
            id        = "yearOfBirth"
            name      = "yearOfBirth"
            component = {SelectInput}
            options   = {getYearsOptions(1900, currentYear - 18, 1)}
          />
        </CustomGrid>
      </SplitGrid>
    </Grid>

  </Layout>
);

PersonalInformation.Title = styled.h3`
  font-size       : 14px;
  font-weight     : bold;
  margin-bottom   : 15px;
  margin-top      : 0px;
  text-decoration : underline;
`;