import { useCallback }      from 'react';
import styled     from 'styled-components';
import { Button } from '../../../../components/Button';


const environment = process.env.REACT_APP_ENVIRONMENT;
export const Review = () => {

  const onReturnButton = useCallback(
    () => {
      window.location.replace(environment === 'production' ? 'https://www.arhaus.com' : 'https://arhausllc.myshopify.com');
    },
    []
  );

  return (
    <>
      <p>
        <strong>Thank you for your interest in the Archarge Credit Card</strong>
      </p>

      <p>      
        We're reviewing the information you provided and will contact you by mail within 10 business days regarding the status of your request.
      </p>

      <p>
        Please return to shopping
      </p>
      <Review.ButtonContainer>
          <Button onClick={onReturnButton}>Return to Shopping</Button>
      </Review.ButtonContainer>
    </>
  )
}

Review.ButtonContainer = styled.div`
  && {
    display         : flex;
    margin-top      : 30px;
    flex-flow       : row nowrap;
    justify-content : center;
  }
`;