export const footerLinks = [
  {
    url      : '/corp/returns-and-exchanges',
    linkText : 'Refund Policy',
  },
  {
    url      : '/corp/delivery-information',
    linkText : 'Shipping Policy',
  },
  {
    url      : '/corp/privacy',
    linkText : 'Privacy Policy',
  },
  {
    url      : '/corp/terms',
    linkText : 'Terms of Service',
  },
];
