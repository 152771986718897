import { useCallback }      from 'react';
import styled     from 'styled-components';
import { Button } from '../../../../components/Button';

const environment = process.env.REACT_APP_ENVIRONMENT;
export const Exists = ({ firstName }) => {

  const onReturnButton = useCallback(
    () => {
      window.location.replace(environment === 'production' ? 'https://www.arhaus.com' : 'https://arhausllc.myshopify.com');
    },
    []
  );

  return (

    <>
      <p>
        <strong>Welcome Back {firstName}!</strong>
      </p>
      
      <p>      
      We have reviewed your information and determined that you have already have a credit card with Arhaus. Please contact <a href="https://comenity.com/contact">customer care </a> for additional assistance.

      </p>

      <p>
        Please return to shopping
      </p>
      <Exists.ButtonContainer>
          <Button onClick={onReturnButton}>Return to Shopping</Button>
      </Exists.ButtonContainer>
    </>
  )
}

Exists.ButtonContainer = styled.div`
  && {
    display         : flex;
    margin-top      : 30px;
    flex-flow       : row nowrap;
    justify-content : center;
  }
`;