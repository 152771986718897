import { useCallback }      from 'react';
import { Button }           from '../../../../components/Button';
import styled from 'styled-components';



const environment = process.env.REACT_APP_ENVIRONMENT;
export const InvalidInformation = () => {

  const onReturnButton = useCallback(
    () => {
      window.location.replace(environment === 'production' ? 'https://www.arhaus.com' : 'https://arhausllc.myshopify.com');
    },
    []
  );

    return (
      <>
        <p>
          <strong>We're Sorry</strong>
        </p>

        <p>
          There has been an un-expected error and this action cannot be completed right now.
        </p>

        <p>
                Please try again later or contact <a href="https://comenity.com/contact" target="_blank" rel="noreferrer">Customer Care </a>.
        </p>

        <InvalidInformation.ButtonContainer>
            <Button onClick={onReturnButton}>Return to Shopping</Button>
        </InvalidInformation.ButtonContainer>
      </>
    )
  }

InvalidInformation.ButtonContainer = styled.div`
  && {
    display         : flex;
    margin-top      : 30px;
    flex-flow       : row nowrap;
    justify-content : center;
  }
`;