const VIEW_CURRENT_TERMS_URL = 'https://d.comenity.net/Arhaus/common/Legal/disclosures.xhtml';
const RETURN_TO_CART_URL     = 'https://www.arhaus.com/Cart/Summary/';

export const Rules = () => (
  <>
    <p>
    Receive 6 or 24 month Special Financing on purchases of $399 for 6 months or $5,000 for 24 months made with your Arhaus Archarge Credit Card at Arhaus Furniture.*
    </p>

    <p>
      The application process is easy and takes only a few minutes. Once you are approved you can begin enjoying all the benefits of your Arhaus Archarge Credit Card today.
    </p>

    <p>
        *Deferred Interest, Low Payment plan: Interest will be charged to your Account from the purchase date
        if the promotional plan balance is not paid in full within the promotional period. Minimum payments are required for each Credit Plan. <strong>Minimum payments are NOT guaranteed to pay the promotional plan balance within the promotional period.
            You may have to pay more than the minimum payment to avoid accrued interest charges.</strong> Valid for single transaction only.
        <strong>Each transaction placed on plans longer than 6 months promotional Credit Plan will not earn reward(s).</strong> As of June 2024, terms for new Accounts: Purchase APR of 35.99%.
        Minimum interest charge is $3.00 in any billing period in which interest is due.
        Existing Cardholders: see your credit card agreement for Account terms.
    </p>

    <p>
      Credit card offers are subject to credit approval.
    </p>

    <p>
      Arhaus Archarge Credit Card Accounts are issued by Comenity Bank.
    </p>
    
    <p>
      <a href={VIEW_CURRENT_TERMS_URL}>
        Click here to view current terms for Archarge Arhaus Credit Card.
      </a>
    </p>

    <p>
      Comenity Bank issues Arhaus Archarge Credit Card Accounts. If you wish to proceed with the online application, you will be providing your personal information to Comenity Bank.
    </p>

    <p>
      <strong>Please review the Important Information and Terms about opening an account prior to submitting your application: </strong>
    </p>

    <p>
      <strong>To apply you must:</strong> Be at the age of majority in your state or territory, 
      Have a valid government-issued photo ID, Have a valid government issued tax identification number (such as a SSN or SIN), 
      Have a street, rural route, or APO/FPO mailing address. We do not accept PO Box mailing addresses.
    </p>

    <p>
            Review our <a href="http://www.comenity.net/arhaus/common/privacy/FinancialPrivacy.xhtml" target="_blank" rel="noreferrer" aria-label="Go to the Comenity Financial Privacy page.">Financial Privacy Policy</a> and <a href="http://www.comenity.net/arhaus/common/privacy/OnlinePrivacy.xhtml" target="_blank" rel="noreferrer" aria-label="Go to the Online and California Privacy Policy page.">Online and California Privacy Policy</a> for more information. California Residents: Visit this page to request that we <a href="https://calprivacy.comenity.com/s/" target="_blank" rel="noreferrer" aria-label="Go to the Comenity Data Request form page.">Do Not Sell My Personal Information</a>.
    </p>


    <p>
      <a href={RETURN_TO_CART_URL}>
        Return to cart.
      </a>
    </p>
  </>
);
