import { Field }                                  from 'formik';
import { Layout }                                 from '../components/Layout';
import { TextInput }                              from '../../TextInput';
import { NumberInput }                            from '../../NumberInput';
import { SelectInput }                            from '../../SelectInput';
import { Grid, SplitGrid, HalfGrid, CustomGrid }  from '../../Grids';
import { Label }                                  from '../../Label';
import { STATES }                                 from '../../../shared/states';

export const ContactInformation = ({ getAddressByZipcode }) => (
  <Layout title="Contact Information">
    <Grid>
      <Label>Email Address</Label>
      <Field id="email" name="email" component={TextInput} />
    </Grid>

    <Grid>
      <SplitGrid>
        <HalfGrid>
          <Label required>Home or Mobile Phone</Label>
          <Field id="phone" name="phone" component={NumberInput} format="###-###-####"  />
        </HalfGrid>

        <HalfGrid>
          <Label>Alternate Phone Number</Label>
          <Field id="workPhone" name="workPhone" component={NumberInput} format="###-###-####"  />
        </HalfGrid>
      </SplitGrid>
    </Grid>

    <Grid>
        <p>
            By providing your contact information above, including any cellular or other phone numbers,
            you agree to be contacted regarding any of your Comenity Bank or Comenity Capital Bank accounts via calls to cell phones,
            text messages or telephone calls, including the use of artificial or pre-recorded message calls,
            as well as calls made via automatic telephone dialing systems, or via e-mail.
        </p>
    </Grid>

    <Grid>
      <SplitGrid>
        <HalfGrid>
          <Label required>Address</Label>
          <Field id="address1" name="address1" component={TextInput} />
        </HalfGrid>

        <HalfGrid>
          <Label>Address 2</Label>
          <Field id="address2" name="address2" component={TextInput} />
        </HalfGrid>
      </SplitGrid>
    </Grid>

    <Grid>
      <SplitGrid>
        <CustomGrid width="32%">
          <Label required>Zip</Label>
          <Field id="zip" name="zip" component={NumberInput} onBlur={getAddressByZipcode} format="#####" />
        </CustomGrid>

        <CustomGrid width="32%">
          <Label required>City</Label>
          <Field id="city" name="city" component={TextInput} />
        </CustomGrid>

        <CustomGrid width="32%">
          <Label required>State</Label>
          <Field id="state" name="state" component={SelectInput} options={STATES} />
        </CustomGrid>
      </SplitGrid>
    </Grid>

    

  </Layout>
);
