import { Approved }            from '../components/Approved';
import { Review }              from '../components/Review';
import { InvalidInformation }  from '../components/InvalidInformation';
import { TechnicalError }      from '../components/TechnicalError';
import { Declined }            from '../components/Declined';
import { Exists }              from '../components/Exists';
import { STATUSES }            from '../../../shared/statuses';

export const ResponsePage = ({ 
  returnCode, 
  firstName, 
  lastName, 
  creditLimit 
}) => (
  <>
    {STATUSES.APPROVED.includes(returnCode) && (
      <Approved 
        firstName   = {firstName}
        lastName    = {lastName}
        creditLimit = {creditLimit}
      />
    )}
    {STATUSES.REVIEW.includes(returnCode) && <Review />}
    {STATUSES.INVALID_INFORMATION === returnCode && <InvalidInformation />}
    {STATUSES.TECHNICAL_ERROR === returnCode && <TechnicalError />}
    {STATUSES.DECLINED.includes(returnCode) && <Declined />}
    {STATUSES.EXISTS.includes(returnCode) && (
      <Exists 
        firstName   = {firstName}
      />
    )}
  </>
);
