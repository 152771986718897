import styled from 'styled-components';

export const Layout = ({ title, children }) => (
  <Layout.Wrapper>
    <Layout.Header>
      <p>{title}</p>
    </Layout.Header>
    
    <Layout.Container>
      {children}
    </Layout.Container>
  </Layout.Wrapper>
);

Layout.Wrapper = styled.div`
  border        : 1px solid #F2F0F2;
  max-width     : 727px;
  min-width     : 320px;
  margin        : 0 auto;
  padding       : 0 15px;
  margin-bottom : 40px;

  label {
    font-size      : 0.75rem;
    display        : inline-block;
    vertical-align : top;
    margin         : 0 0 9px;
  }

  @media screen and (min-width: 376px) {
    label {
      font-size : 0.875rem;
      margin    : 0 0 5px;
    }
  }

  @media screen and (min-width: 557px) {
    padding: 0;
  }
`;

Layout.Header = styled.div`
  background-color : #f8f8f8;
  padding          : 12px 10px;
  display          : flex;
  flex-flow        : row nowrap;
  justify-content  : space-between;
  font-size        : 0.75rem;

  p {
    margin          : 15px 0 15px 0;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : flex-start;
    align-items     : center;
  }

  @media screen and (min-width: 376px) {
    padding   : 17px 15px;
    font-size : 0.85rem;
  }
`;

Layout.Container = styled.div`
  display               : grid;
  grid-template-columns : 100%;
  padding               : 0 10px 15px;
  grid-gap              : 15px;

  @media screen and (min-width: 376px) {
    padding  : 0 19px 30px;
    grid-gap : 20px;
  }
`;
