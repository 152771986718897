import { Field }       from 'formik';
import { Layout }      from '../components/Layout';
import { NumberInput } from '../../NumberInput';
import { Grid }        from '../../Grids';
import { Label }       from '../../Label';

export const AnnualIncome = () => (
  <Layout title="Annual Income">
    <Grid>
      <Label required>Annual Income</Label>
      <Field 
        id        = "annualIncome"
        name      = "annualIncome"
        component = {NumberInput}
        prefix    = "$"
        thousandSeparator 
      />
    <p>
        
        Alimony, child support or separate maintenance income need not to be included if you do not wish to have it considered as a basis for repaying this obligation.

        <strong>Married WI Residents only:</strong>
        If you are applying for an individual account and your spouse also is a WI resident, combine your and your spouse's financial information.
    </p>
    </Grid>

  </Layout>
);
