import styled from 'styled-components';

export const BlockHeader = styled.h2(({ theme }) => {
  const { breakpoint, type } = theme;

  return `
    font-size      : 0.75rem;
    font-weight    : ${type.weight.bold};
    text-transform : uppercase;
    margin         : 37px 0 10px 0;
    color          : #000;

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size : 1rem;
      margin    : 45px 0 19px 0;
    }
  `;
});
