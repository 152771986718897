import styled from 'styled-components';

export const Checkbox = ({ field, label, form: { errors } }) => (
  <Checkbox.Wrapper hasError={errors[field.name]}>
    <Checkbox.StyledInput type="checkbox" id="subscribeNews" name="subscribe" value="newsletter" {...field} />
    {label}
  </Checkbox.Wrapper>
);

Checkbox.StyledInput = styled.input`
  margin-right : 10px;
  margin-top   : 3px;
  width        : 150px;
  height       : 25px;
  cursor       : pointer;

  &:checked:after{
    background-color:green;
  }
`;

Checkbox.Wrapper = styled.div`
  display    : flex;
  text-align : justify;

  label {
    ${({ hasError }) => hasError && `color: red;`}
  }
`;