import { Formik, Form }        from 'formik';
import * as Yup                from 'yup';
import { Rules }               from '../components/Rules'
import { FormikOnError }       from '../../../components/FormikOnError';
import { PersonalInformation } from '../../../components/forms/PersonalInformation';
import { ContactInformation }  from '../../../components/forms/ContactInformation';
import { AnnualIncome }        from '../../../components/forms/AnnualIncome';
import { AuthorizedBuyers }    from '../../../components/forms/AuthorizedBuyers';
import { ElectronicConsent }   from '../../../components/forms/ElectronicConsent';

const phoneRegEx        = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;
const ssnRegEx          = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/i;
const zipRegEx          = /^[0-9]{5}$/i;

const initialValues = {
  firstName     : '',
  lastName      : '',
  middleInitial : '',
  ssn           : '',
  email         : '',
  phone         : '',
  workPhone     : '',
  address       : '',
  address2      : '',
  state         : '',
  city          : '',
  zip           : '',
  annualIncome  : '',
  isApply       : false,
  dayOfBirth    : null,
  monthOfBirth  : null,
  yearOfBirth   : null
}

const validationSchema = Yup.object().shape({
  firstName    : Yup.string().required('First Name is a required'),
  lastName     : Yup.string().required('Last Name is a required'),
  ssn          : Yup.string().required('SSN is a required').test('invalid_format', 'Invalid format', value => ssnRegEx.test(value)),
  dayOfBirth   : Yup.string().required('Day is a required').nullable(),
  monthOfBirth : Yup.string().required('Month is a required').nullable(),
  yearOfBirth  : Yup.string().required('Year is a required').nullable(),
  phone        : Yup.string().required('Home or Work Phone is a required').test('invalid_format', 'Invalid format', value => phoneRegEx.test(value)),
  address1     : Yup.string().required('Address is a required'),
  state        : Yup.string().required('State is a required'),
  city         : Yup.string().required('City is a required'),
  zip          : Yup.string().required('Zip is a required').test('invalid_format', 'Invalid format', value => zipRegEx.test(value)),
  annualIncome : Yup.string().required('Annual Income is a required'),
  isApply      : Yup.boolean().required().oneOf([true])
}, [['workPhone']]);

export const MainPage = ({ 
  onSubmit, 
  getAddressByZipcode, 
  formikRef,
  validateOnChange,
  setValidateStatus,
  loading
}) => (
  <>
    <Rules />

    <Formik
      innerRef         = {formikRef}
      initialValues    = {initialValues}
      validationSchema = {validationSchema}
      validateOnChange = {validateOnChange}
      validateOnBlur   = {false}
      onSubmit         = {onSubmit} 
    >
      <Form>
        <input name="blackBox" type="hidden" id="blackBox" />
        <FormikOnError setValidateStatus={setValidateStatus}>
          <PersonalInformation />
          <ContactInformation getAddressByZipcode={getAddressByZipcode} />
          <AnnualIncome />
          <AuthorizedBuyers />
          <ElectronicConsent loading={loading} />
        </FormikOnError>
      </Form>
    </Formik>
  </>
);
