import styled        from 'styled-components';
import { Button }    from '../../../components/Button';
import { BlockHeader } from '../../../components/BlockHeader';

export const MaitenancePage = () => {
  
  return (
    <MaitenancePage.Wrapper>
      <MaitenancePage.Container>
        <MaitenancePage.Section>
          <h2>Down for Maitenance</h2>
        </MaitenancePage.Section>
      <BlockHeader> We are sorry for the inconvience, at this time the Archarge credit card online application is down for maintenace. Please visit your 
        nearest Arhaus location to apply in store.
      </BlockHeader>
      <MaitenancePage.ButtonContainer>
        <Button type="button" onClick={()=>{window.location.href ="https://arhaus.com"}}> Return to Arhaus.Com </Button>
      </MaitenancePage.ButtonContainer>
      </MaitenancePage.Container>
    </MaitenancePage.Wrapper>
  )
}

MaitenancePage.Wrapper = styled.div`
  width           : 100%;
  display         : flex;

`;

MaitenancePage.Container = styled.div`
  vertical-align   : middle;
  line-height      : normal;

`;

MaitenancePage.ButtonContainer = styled.div`
  && {
    display         : flex;
    margin-top      : 17px;
    margin-bottom   : 5px;
    flex-flow       : row nowrap;
    justify-content : center;
  }
`;

MaitenancePage.Section = styled.section`
text-align : left;
box-sizing : border-box;
display    : block;
padding    : 30px;
text-align : center;

h2 {
  text-align: center;
}
`;
