import styled from 'styled-components';

export const Grid = styled.div`
  margin-top : 20px;
  text-align : left;
`;

export const CustomGrid = styled.div`
  width: ${({ width }) => `${width}`};
`;

export const QuarterGrid = styled.div`
  width      : calc(25% - 5px);
  text-align : left;
`;

export const HalfGrid = styled.div`
  width      : calc(50% - 5px);
  text-align : left;
`;

export const SplitGrid = styled.div`
  display         : flex;
  flex-flow       : row wrap;
  justify-content : space-between;
`;